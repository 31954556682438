<template>
  <section class="is-primary is-fullheight login-page">
    <div class="login-background">
      <div class="columns is-centered">
        <div class="column">

        </div>
        <div class="column is-one-third">
          <div class="card card-login-form">
            <div class="card-box">
              <header class="card-header">
                <div class="columns row-title">
                  <div class="column column-logo is-full is-size-1">
                    <img width="154px" class="logo" src="../../assets/svgs/Logo/logo_color.svg" alt="">
                  </div>
                </div>
              </header>
            </div>
            <div class="card-content">
              <div class="content" @keyup.enter="resetPassword()">
                <div class="content-title">
                  <span>{{$t('login.content_title')}}</span>
                </div>
                <div class="field has-text-centered p__top--30">
                  <div class="field m__bottom--20">
                    <div class="control has-icons-left">
                      <input type="password" v-model="password" :placeholder="$t('login.input_field.reset_password.password')"
                             @keyup.enter="resetPassword()"
                             :class="{'is-error' : ((!$v.password.required || !$v.password.minLength) && $v.$dirty) || error_server.password && error_server.password.length}"
                             class="input is-large reset-password">
                      <span class="icon is-medium is-left">
                        <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                      </span>
                    </div>
                    <template v-if="$v.$dirty">
                      <span class="error pt-1" v-if="!$v.password.required"> {{ $t('reset_password.messages.password_required') }}</span>
                      <span class="error pt-1" v-if="$v.password.required && !$v.password.minLength"> {{
                          $t('reset_password.messages.password_minlength')
                        }}</span>
                      <span class="error pt-1" v-if="$v.password.required && !$v.password.maxLength"> {{
                          $t('reset_password.messages.password_maxlength')
                        }}</span>
                    </template>
                    <span class="error pt-1" v-else-if="error_server.password && error_server.password.length">
                    {{ error_server.password[0] }}
                  </span>
                  </div>
                  <div class="field m__bottom--30">
                    <div class="control has-icons-left">
                      <input type="password" v-model="password_confirmation"
                             @keyup.enter="resetPassword()"
                             :placeholder="$t('login.input_field.reset_password.password_confirm')"
                             :class="{'is-error' : (!$v.password_confirmation.required || !$v.password_confirmation.sameAs) && $v.$dirty}"
                             class="input is-large reset-password">
                      <span class="icon is-medium is-left">
                        <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                      </span>
                    </div>
                    <template v-if="$v.$dirty">
                      <span class="error pt-1"
                            v-if="!$v.password_confirmation.required"> {{
                          $t('reset_password.messages.password_confirmation_required')
                        }}</span>
                      <span class="error pt-1"
                            v-if="$v.password_confirmation.required && !$v.password_confirmation.sameAs"> {{
                          $t('reset_password.messages.password_confirmation_not_match')
                        }}</span>
                    </template>
                  </div>
                  <div class="field has-text-centered m__top--30">
                    <button @click="resetPassword()" class="button btn-reset-password is-align-items-flex-end">
                      {{ $t('buttons.reset') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>


<script>
  import {sameAs, maxLength, minLength, required} from "vuelidate/lib/validators";
  import {AuthService} from '@/services';

  export default {
    name: "ForgotPassword",
    data() {
      return {
        password_confirmation: "",
        password: "",
        error_server: {},
        submitAvailable: true
      };
    },
    validations: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(16)
      },
      password_confirmation: {
        required,
        sameAs: sameAs(function () {
          return this.password
        })
      },
    },
    methods: {
      resetPassword() {
        this.error_server = {}
        this.$v.$touch();
        if (!this.$v.$invalid && this.submitAvailable)
        {
          this.submitAvailable = false
          AuthService.resetPassword(this.$route.params.token, this.password, this.password_confirmation)
            .then(() => {
              this.submitAvailable = true
              this.$router.push({name: "login"})
              this.$toast.success(this.$t('reset_password.messages.success'))
            })
            .catch((err) => {
              this.submitAvailable = true
              this.error_server = err.data.errors
              if (this.error_server.token) {
                this.$toast.error(err.data.errors.token)
              }
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/login.scss";
</style>
